import React from 'react'
import JanChris from '../images/jan_chris.jpg'

const AboutUs = () => {
  return (
    <>
      <h2>About us</h2>
      <p>
        Janine Torres, Owner and Director, of Grove Hill Property Reports.
        Janine, originally from Northern Ireland has worked all her adult life
        in Real Estate. Starting as a receptionist, she quickly moved to a
        Client Liaison role which is where she experienced true Property Manager
        life. After gaining the required qualifications in 2009, Janine delved
        straight into portfolio life never looking back.
      </p>
      <p>
        <img className="janchris" src={JanChris} />
      </p>
      <p>
        Over the years Janine has been commended by both clients and management
        for having a superb problem solving ability, excellent communication and
        organisational skills as well as attention to detail, ensuring that not
        only is the job completed, but that it is done to perfection.
      </p>
      <p>
        The idea for Grove Hill Property Reports came when Janine saw room for
        improvement within outsourcers reports and their services.
      </p>
      <p>
        Grove Hill Property Reports is a family run and operated business based
        in the northern suburbs of Perth; however, we are more than happy to
        travel.
      </p>
      <p>
        Contact Janine today to discuss all your outsourcing needs - you won't
        be disappointed!
      </p>
    </>
  )
}

export default AboutUs
