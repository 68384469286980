import React from 'react'

const Services = () => {
  return (
    <>
      <h2>Services</h2>
      <p>
        <strong>
          At Grove Hill Property Reports we offer a variety of services. Every
          task will be carried out with precision and we will treat every
          property like our own.
        </strong>
      </p>
      <p>
        <strong>Routine Inspections:</strong> Provide us with a list of
        inspections due to be carried out over the course of the month and we
        will schedule them for you! All that you have to do is send the Form
        19's and have the keys ready. You will receive the report direct to your
        inbox within 24 hours. We can complete these using an App of your choice
        or complete it in our own template. We complete our inspections as if
        the owner were there, giving an accurate representation of how their
        property is being maintained, offering recommendations for items that
        the tenant may need to repair.
      </p>
      <p>
        <strong>Final Bond Inspections:</strong> We can collect your ingoing
        Property Condition Report with keys and go through each individual room
        outlining any differences
      </p>
      <p>
        <strong>Home Opens:</strong> Let us know when you require these to be
        completed. Our service provides collecting the keys from your office,
        completing the home open and returning the keys the same day if
        possible.
      </p>
      <p>
        <strong>Rewriting Reports:</strong> Is your PCR in an old format and
        needs reformatting? Let us do that time consuming task for you! Simply
        send through the old report and we will email you the newly formatted
        report within 24 hours!
      </p>
    </>
  )
}

export default Services
