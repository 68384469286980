import { useEffect } from 'react'
import './App.css'
import AboutUs from './components/aboutus'
import Services from './components/services'
import logo from './images/grovhill-logo.png'

const App = () => {
  useEffect(() => {
    const anchorlinks = document.querySelectorAll('a[href^="#"]')

    for (const item of anchorlinks) {
      // relitere
      item.addEventListener('click', e => {
        const hashval = item.getAttribute('href') as string

        const target = document.querySelector(hashval)
        target?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        history.pushState(null, '', hashval)
        e.preventDefault()
      })
    }
  }, [])

  return (
    <>
      <div className="navigation">
        <a href="#services">Our services</a>
        <a href="#about">About us</a>
        <a href="#contact">Contact us</a>
      </div>
      <div className="bgimage1">
        <div className="front-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>

      <div id="services" className="content services">
        <Services />
      </div>

      <div className="bgimage2"></div>

      <div id="about" className="content about">
        <AboutUs />
      </div>

      <div className="bgimage3"></div>

      <div className="footer">
        <div className="footerContent">
          <div className="left">
            <h2>Links</h2>
            <a href="#services">Our services</a>
            <a href="#about">About us</a>
            <h2 id="contact">Contact us</h2>
            <div className="phone">Janine: 0411 259 402</div>
            <div className="phone">Chris: 0447 752 422</div>
            <a href="mailto:info@grovehillproperty.com.au?subject=Website Enquiry">
              info@grovehillproperty.com.au
            </a>
          </div>

          <div className="right">
            <img src={logo} alt="logo" />
            <div className="abn">ABN: 18772406726</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default App
